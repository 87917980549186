$(function () {
  // Init Menu
  $('nav').on('click','.navigation-toggle',function (event) {
    $('body').toggleClass('no-scroll');

    var nav = $(this).parents('nav');
    var height = parseInt(nav.find('.navigation-component').height()) + 60;

    if($(this).hasClass('is-active')) {
      nav.find('.main-navigation').css('height','');
    } else {
      nav.find('.main-navigation').css('height',height);
    }

    $(this).toggleClass('is-active');
    nav.find('.main-navigation').toggleClass('in');
  });

  initSearchBox();
});
