$(function () {
  // Scroll-up component
  var handler = function (event) {
    if($('footer .scroll-up').length) {
      $('footer .scroll-up .scroll-up-inner').toggleClass('docked',($('footer .scroll-up .scroll-up-helper').offset().top >= $('footer .footer-menu').offset().top));
    }
  }
  $(window).on('resize',handler);
  $(document).on('scroll',handler).trigger('scroll');

  if($('footer .scroll-up').length) {
    $('footer .scroll-up').on('click','.scroll-up-inner',function (event) {
      $('html').animate({
  			scrollTop: 0
  		}, 700);
    })
  }

})
