$(function () {
  if ($('body').hasClass('partners')) {
    var belongingMap = $('.google-map-canvas').data('map-index');

    // Apply aspect-ratio-hack padding as height for inner elements
    $(window).on('resize', function (event) {
      $('.partner-gallery > .partner-element').each(function () {
        // Reset
        $(this).find('.inner').css('height', '');
        $(this).css('padding-bottom', '');
        // Set
        $(this).find('.inner').css('height', $(this).outerHeight());
        $(this).css('padding-bottom', '0');
      })
    }).trigger('resize');

    // Filter function for Isotope.js
    function applyFiltering(index, elem) {
      var result = true;

      if ($('.filter-container').find('.filter-button').hasClass('active')) {
        result = (result && $(elem).is($('.filter-container').find('.filter-button.active').data('filter')));
      }

      var input = $('#partners-search').val().toLowerCase();
      if (input !== '') {
        var metaTag = $(elem).find('.meta-information');
        var name = metaTag.find('.value-name').html().toLowerCase();
        var contact = metaTag.find('.value-contact').html().toLowerCase();
        var intro = metaTag.find('.value-intro').html().toLowerCase();
        var keywords = metaTag.find('.value-addkeys').html().toLowerCase();

        if (input.match(/\W+/g)) {
          var words = input.split(/\W+/g);
          for (var i = 0; i < words.length; i++) {
            result = (result && (name.indexOf(words[i]) > -1 || contact.indexOf(words[i]) > -1 || intro.indexOf(words[i]) > -1 || keywords.indexOf(words[i]) > -1));
          }
        } else {
          result = (result && (name.indexOf(input) > -1 || contact.indexOf(input) > -1 || intro.indexOf(input) > -1 || keywords.indexOf(input) > -1));
        }
      }

      $(elem).toggleClass('filtered', !result);

      return result;
    }

    function drawMarkers(map) {
      var enabled = [], disabled = [];
      $('.partner-gallery .partner-element').each(function () {
        if ($(this).hasClass('filtered')) {
          disabled.push($(this).attr('id'));
        } else {
          enabled.push($(this).attr('id'));
        }
      })

      var step = 0.1;
      function fadeInMarker(map, id) {
        if (window.maps[map].markers[id].opacity < step) {
          window.maps[map].markers[id].marker.setMap(window.maps[map].mapObject);
        }

        if (window.maps[map].markers[id].opacity < 1) {
          window.maps[map].markers[id].opacity = window.maps[map].markers[id].opacity + step;
          window.maps[map].markers[id].marker.setOpacity(window.maps[map].markers[id].opacity);

          setTimeout(function () {
            fadeInMarker(map, id);
          }, 25);
        } else {
          window.maps[map].markers[id].opacity = 1;
          window.maps[map].markers[id].marker.setOpacity(1);
        }
      }

      function fadeOutMarker(map, id) {
        if (window.maps[map].markers[id].opacity > step) {
          window.maps[map].markers[id].opacity = window.maps[map].markers[id].opacity - step;
          window.maps[map].markers[id].marker.setOpacity(window.maps[map].markers[id].opacity);

          setTimeout(function () {
            fadeOutMarker(map, id);
          }, 25);
        } else {
          window.maps[map].markers[id].opacity = 0;
          window.maps[map].markers[id].marker.setOpacity(0);

          window.maps[map].markers[id].marker.setMap(null);
        }
      }

      function findMarker(map, rel) {
        return window.maps[map].markers.filter(function (elem) {
          return (elem.detail.relation === rel);
        })[0];
      }

      function getMarkerIndex(map, marker) {
        for (var i = 0; i < window.maps[map].markers.length; i++) {
          if (window.maps[map].markers[i] == marker) return i;
        }
      }

      disabled.forEach(function (e, i, a) {
        var m = findMarker(map, e);
        var ii = getMarkerIndex(map, m);

        fadeOutMarker(map, ii);
      })
      
      enabled.forEach(function (e, i, a) {
        var m = findMarker(map, e);
        var ii = getMarkerIndex(map, m);

        fadeInMarker(map, ii);
      })
    }

    // Initializing Isotope grid and according event listeners
    $('.partner-gallery').isotope({
      layoutMode: 'fitRows'
    })

    $('.filter-container').on('click', '.filter-button', function (event) {
      $(this).parent().find('.filter-button').removeClass('active');
      $(this).toggleClass('active', true);
      $(this).parent().find('.remove-filters').toggleClass('in', true);

      $('.partner-gallery').isotope({
        filter: applyFiltering
      })

      if (window.user_consented_to_cookies === true) {
        drawMarkers(belongingMap);
      }
    })

    $('.filter-container .remove-filters').on('click', function (event) {
      $(this).removeClass('in');
      $(this).parent().find('.filter-button').removeClass('active');

      $('.partner-gallery').isotope({
        filter: applyFiltering
      })

      if (window.user_consented_to_cookies === true) {
        drawMarkers(belongingMap);
      }
    })

    var ready = true, timer;
    $('#partners-search').on('keyup paste', function (event) {
      if (!ready) {
        timer = clearTimeout(timer);
      }
      ready = false;

      var input = $(this).val().toLowerCase();
      var icon = $(this).siblings('.search-button').find('.glyphicons');

      icon.toggleClass('glyphicons-search', (input === ''));
      icon.toggleClass('glyphicons-remove', (input !== ''));

      timer = setTimeout(function () {
        $('.partner-gallery').isotope({
          filter: applyFiltering
        })

        if (window.user_consented_to_cookies === true) {
          drawMarkers(belongingMap);
        }

        ready = true;
      }, 250);
    }).siblings('.search-button').on('click', '.glyphicons-remove', function (event) {
      $('#partners-search').val('');

      $(this).toggleClass('glyphicons-search', true);
      $(this).toggleClass('glyphicons-remove', false);

      $('.partner-gallery').isotope({
        filter: applyFiltering
      })

      if (window.user_consented_to_cookies === true) {
        drawMarkers(belongingMap);
      }
    })
  }
})
