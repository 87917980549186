function initSearchBox() {
  $('#obis-search-searchTerm-input').on('focus', function (event) {

    // Focus listener for expanding the search box
    var remainingWidth = $('nav > .inner').innerWidth();

    $('nav > .inner').children(':not(.search-result-box):not(.main-navigation)').each(function (elem) {
      remainingWidth -= $(this).outerWidth();
    })

    var width = ($(this).parent().outerWidth() + remainingWidth) * 0.8;

    if (width > 500) {
      width = 500;
    }

    $(this).css('width', width + 'px');

  }).on('blur', function (event) {

    // Focus listener for collapsing the search box
    if (!$(this).val()) {
      $(this).css('width', '');
    }

  }).on('input', function (event) {

    // Input listener for enabling/disabling the submit button
    if ($(this).val()) {
      $('#search-trigger-button').removeAttr('disabled');
    } else {
      $('#search-trigger-button').attr('disabled', 'disabled');
    }

  }).on('keypress', function (event) {

    // Input listener for submit when pressing enter
    if (event.which === 13) {
      $('#search-trigger-button').click();
    }

  })

  var currentRunningSearchRequest = null;

  function cancelCurrentRequestIfAvailable(message) {
    if (currentRunningSearchRequest !== null) {
      if (message) {
        console.warn(message);
        console.log(currentRunningSearchRequest);
      }

      currentRunningSearchRequest.abort();
    }
  }

  $('nav > .inner > .search-result-box > .close-icon-button').on('click', function (event) {
    cancelCurrentRequestIfAvailable('Canceling active search request!');
    $('nav > .inner > .search-result-box').removeClass(['loading', 'finished', 'no-results']);
  })

  $('#search-trigger-button').on('click', function (event) {
    if ($(this).attr('disabled') === 'disabled') {
      return;
    }

    var searchTerm = $('#obis-search-searchTerm-input').val();

    if (!searchTerm) {
      return;
    }

    var resultBox = $('nav > .inner > .search-result-box');
    var actualResultBox = resultBox.children('.actual-results');

    cancelCurrentRequestIfAvailable('Preventing duplicate search request!');

    currentRunningSearchRequest = $.ajax({
      type: 'post',
      url: '/search',
      data: {
        searchTerm: searchTerm
      },
      dataType: 'json',
      global: false,
      beforeSend: function (jqxhr, settings) {
        actualResultBox.html('');
        resultBox.removeClass(['finished', 'no-results']).addClass('loading');
        resultBox.find('.no-results-message .search-term-variable').html(searchTerm);
      },
      success: function (data, status, jqxhr) {
        if (status === 'success') {
          resultBox.removeClass('loading').addClass('finished');

          if (jQuery.isEmptyObject(data)) {
            resultBox.addClass('no-results');
            return;
          }

          for (var identifier in data) {
            var details = data[identifier];

            actualResultBox.append(buildSearchResultItem(details));
          }
        }
      },
      complete: function (jqxhr, status) {
        if (status !== 'abort') {
          currentRunningSearchRequest = null;
        }
      }
    })
  })
}

function buildSearchResultItem(itemDetails) {
  if (!isset(itemDetails.title, 'string')) {
    throw new TypeError("Missing required argument 'title' for search result item template!");
  } else if (!isset(itemDetails.link, 'string')) {
    throw new TypeError("Missing required argument 'link' for search result item template!");
  }

  // if (!isset(itemDetails.icon, 'string')) {
  //   throw new TypeError("Missing required argument 'icon' for search result item template!");
  // } else if (!isset(itemDetails.color, 'string')) {
  //   throw new TypeError("Missing required argument 'color' for search result item template!");
  // }

  var tpl = $('#search-result-item-template').html();

  tpl = tpl.replace('[TITLE]', itemDetails.title);
  tpl = tpl.replace('[HREF]', itemDetails.link);
  tpl = tpl.replace('[ICON]', itemDetails.icon || 'fa-file');
  tpl = tpl.replace('[COLOR]', itemDetails.color || 'unset');

  return tpl;
}
