$(function() {
	var protocol = 'http://';
	var sec_protocol = 'https://';
  var tel_protocol = 'tel:';
  var mail_protocol = 'mailto:';
	var host = document.location.hostname;

  $('main a').filter(function (i,e) {
    return !(
      // ignore custom html content to prevent incompatibility
      $(this).parents('.neos-nodetypes-html').length > 0 ||
      // ignore all links with the classname "helper-ignore" entirely
      $(this).hasClass('helper-ignore')
    );
  }).each(function _invoke() {
    var href = $(this).attr('href');
    var target = null, classname;

    if(href !== undefined && href !== null && href !== false && href !== '') {
      if(href.startsWith(tel_protocol) || href.startsWith(mail_protocol)) {
        target = '_blank';
        classname = 'type-other';
      } else if(href.startsWith(protocol) || href.startsWith(sec_protocol)) {
        if(href.includes(host)) {
          if(href.includes('_Resources')) {
            target = '_blank';
            classname = 'type-resource';
          } else {
            target = '_self';
            classname = 'type-internal';
          }
        } else {
          target = '_blank';
          classname = 'type-external';
        }
      } else if(href.startsWith('/')) {
        target = '_self';
        classname = 'type-internal';
      } else {
        target = '_blank';
        classname = 'type-external';
      }
    } else {
      classname = 'type-invalid';
    }

    if ($(this).find('img').length > 0) {
      classname += ' no-icon';
    }

    $(this).attr('target',target).addClass(classname);
  });
})
