$(function () {
  function _stripTags(html) {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  function _convertToId(id) {
    id = id.replace(/[^A-Za-z0-9-_]/g, '_');
    id = id.toLowerCase();

    if ($('#' + id).length > 0) {
      id += '-' + $('[id^="' + id + '"]').length;
    }

    return id;
  }

  $('h1,h2,h3,h4,h5,h6').each(function (index, element) {
    var hasAnchor = $(this).is('[id]');

    if ($(this).attr('id') === undefined || $(this).attr('id') === false || $(this).attr('id') === null) {
      var content = _stripTags($(this).html());
      var anchor = _convertToId(content);

      $(this).attr('id', anchor);
    }
  });
});
