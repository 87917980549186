$(function () {
  var init = function () {
    if ($('.slider-ad').length) {
      $('.slider-ad').each(function () {
        var config = window.multisliderconfigs[$(this).attr('id')];

        $(this).find('.slick-wrapper').slick({
          fade: true,
          autoplay: true,
          autoplaySpeed: (config.duration * 1000),
          arrows: false,
          dots: false,
          draggable: false,
          accessibility: false,
          cssEase: 'ease-in-out',
          easing: 'swing',
          infinite: true,
          swipe: false,
          touchMove: false,
          waitForAnimate: true,
          pauseOnFocus: false,
          pauseOnHover: false,
          pauseOnDotsHover: false
        })
      })
    }
  }

  document.addEventListener('Neos.PageLoaded', init);
  init();
})
