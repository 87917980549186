$(function () {
  var base = $('.advertisement-area > .container > .neos-contentcollection');

  if(base.length) {
    base.slick({
      accessibility: false,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 10000,
      arrows: false,
      centerMode: false,
      centerPadding: 0,
      dots: false,
      draggable: false,
      infinite: true,
      mobileFirst: true,
      speed: 500,
      swipe: false,
      touchMove: false,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 425, // XS view
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768, // SM view
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 992, // MD view
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1200, // LG view
          settings: {
            slidesToShow: 5
          }
        }
      ]
    })

    $(window).on('resize',function (event) {
      base.slick('setPosition')
    })
  }
})
