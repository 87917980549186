$(function() {
  window.initSwipers();
});

window.initSwipers = function () {
  // Init Swiper
  window.swiper = window.swiper || [];

  $('.swiper-container').each(function (index) {
    var $el = $(this);

    if ($el.find('.swiper-slide').length <= 1 ||  $('body').hasClass('neos-backend')) {
      var config = {
        initialSlide: 0,
        speed: 400,
        pagination: $el.find('.swiper-pagination')[0],
        paginationClickable: true,
        autoplay: false,
        autoplayDisableOnInteraction: false,
        simulateTouch: false,
        loop: false
      }
    } else {
      var config = {
        initialSlide: 0,
        speed: 400,
        pagination: $el.find('.swiper-pagination')[0],
        paginationClickable: true,
        autoplay: 10000,
        autoplayDisableOnInteraction: false,
        simulateTouch: false,
        loop: true
      }
    }

    window.swiper[index] = $el.swiper(config);

    $el.find('.prev-slide').on('click', function () {
      window.swiper[index].slidePrev();
    })

    $el.find('.next-slide').on('click', function () {
      window.swiper[index].slideNext();
    })

    if ($el.find('.swiper-pagination').length > 0) {

      var rightWidth = parseInt($el.find(' .head .head-inner').css('marginRight')) + parseInt($el.find(' .head .head-inner').css('paddingRight'));
      $el.find('.swiper-pagination').css('right', rightWidth + 'px');

    }

    $(window).resize(function () {

      window.swiper[index].update();
      window.swiper[index].slideTo();
      window.swiper[index].startAutoplay();

      if ($el.find('.swiper-pagination').length > 0) {

        var rightWidth = parseInt($el.find(' .head .head-inner').css('marginRight')) + parseInt($el.find(' .head .head-inner').css('paddingRight'));
        $el.find('.swiper-pagination').css('right', rightWidth + 'px');

      }

    })

  })
}
