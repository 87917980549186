$(function() {
  document.querySelectorAll('div.obis-site-iframe').forEach(function (element) {
    element.querySelector('button.grant-consent-button').addEventListener('click', function (event) {
      element.classList.add('has-consented');

      var frame = element.querySelector('iframe');
      if (!frame.classList.contains('loaded')) {
        frame.setAttribute('src', frame.getAttribute('data-source'));
        frame.removeAttribute('data-source');
        frame.classList.add('loaded');
      }
    });
  });
});
