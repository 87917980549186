$(function () {

    $('.social-button.mocked-share-button').each(function (elem) {

        $(this).on('click', function (event) {

            var targetUrl = $(this).data('target'),
                windowHeight = $(this).data('popup-height') || 500,
                windowWidth = $(this).data('popup-width') || 400;

            var posLeft = ($(window).width() / 2) - (windowWidth / 2),
                posTop = ($(window).height() / 2) - (windowHeight / 2);

            window.open(
                targetUrl, 
                '_blank', 
                'height=' + windowHeight + ',width=' + windowWidth + ',left=' + posLeft + ',top=' + posTop +
                ',menubar=0,resizable=1,scrollbars=1,status=0,toolbar=0',
                false
            );

        })

    })

})