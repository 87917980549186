$(function() {
  moment.locale((window.navigator.userLanguage || window.navigator.language));

  $('.convert-moment-date').each(function(index, elem) {
    var format = $(elem).data('format') || 'L';
    var dateString = $(elem).text();

    if (!isset(format, 'string') || !isset(dateString, 'string')) {
      return;
    }

    var locale = $(elem).data('locale');
    if (isset(locale, 'string')) {
      moment.locale(locale);
    }

    var date = moment(dateString, 'YYYY-MM-DD\THH:mm:ssZZ');
    var formatted = date.format(format);

    $(elem).text(formatted);

    if (!$(elem).data('immutable')) {
      $(elem).removeAttr('data-format').removeClass('convert-moment-date');
    }
  });
});
