$(function() {
  function setSpinner(element) {
    $(element).html('<i class=" ajax-content fa fa-spinner fa-pulse"></i>');
  }

  function setNotice(element, gdprLink, contactLink, phoneLink, mailLink) {
    $(element).html('<p class="noconsent-notice"><b>Hinweis!</b><br>Wir können Ihnen das Kontaktformular aufgrund Ihrer aktuellen Cookie-Einstellungen leider nicht zur Verfügung stellen. Bitte nutzen Sie den direkten <a href="' + contactLink + '" target="_self">Kontakt</a> zu uns über ' + mailLink + ' oder ' + phoneLink + '. Weitere Informationen erhalten Sie in unserer <a href="' + gdprLink + '" target="_self">Datenschutzerklärung</a>.</p>');
  }

  var forms = $('div.ajax-form');
  var firstFormWrapper = forms.first().parent();
  var gdprLink = firstFormWrapper.data('gdpr-link');
  var contactLink = firstFormWrapper.data('contact-link');

  $(window).on('ajaxStart', function(event) {
    setSpinner('div.ajax-form')
  }).on('ajaxStop', function(event) {
    if (!(isset(window._canInitAjaxForm, 'boolean') && window._canInitAjaxForm)) {
      setNotice('div.ajax-form', gdprLink, contactLink, 'Telefon', 'E-Mail');
    }
  });

  var tries = 0;

  function _tryToInit() {
    if (!(isset(window._canInitAjaxForm, 'boolean') && window._canInitAjaxForm)) {
      if (tries > 5) {
        setNotice($('div.ajax-form'), gdprLink, contactLink, 'Telefon', 'E-Mail');
      } else {
        tries++;
        setTimeout(_tryToInit, 500);
      }
    } else {
      // workaround for not initializing forms as consent happens after document.ready
      initAjaxForm();
    }
  }

  setTimeout(_tryToInit, 500);
});