document.addEventListener('DOMContentLoaded', function () {
  var cdisplays = document.querySelectorAll('.obis-site-cdisplay.type-code');
  if (cdisplays.length < 1) {
    return;
  }

  window._cd = {};

  function processItem(element) {
    if (element.querySelector('img') !== null) {
      var imgs = element.querySelectorAll('img');
      window._cd[element.id] = {
        total: imgs.length,
        loaded: 0,
      }

      for (var i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        if (img.complete) {
          window._cd[element.id].loaded++;
          continue;
        }

        img.addEventListener('load', function () {
          window._cd[element.id].loaded++;

          if (window._cd[element.id].loaded == window._cd[element.id].total) {
            processItem(element);
            delete window._cd[element.id];
          }
        });
      }

      if (window._cd[element.id].loaded < window._cd[element.id].total) {
        return;
      }
    }

    var content = element.querySelector('.inner .content');
    var contentHeight = content.offsetHeight;
    var elementHeight = element.offsetHeight;

    if (contentHeight < elementHeight) {
      var inner = element.querySelector('.inner');
      var innerHeight = inner.getAttribute('data-y');
      var currentStyle = inner.getAttribute('style');

      if (currentStyle === null) {
        currentStyle = '';
      } else if (!currentStyle.endsWith(';')) {
        currentStyle = currentStyle + ';';
      }

      inner.setAttribute('style', currentStyle + 'padding-top:min(' + contentHeight + 'px,' + innerHeight + ');');
    }
  }

  for (var i = 0; i < cdisplays.length; i++) {
    const element = cdisplays[i];
    if (element.classList.contains('invalid') || element.classList.contains('format-banner')) {
      // dont process invalid ads and banner ads, as these depend on their height
      return;
    }

    processItem(element);
  }
});
