$(function () {
  if($('.partner-element').length) {
    $(window).on('resize',function (event) {
      // $('.partner-element').each(function () {
      //   // Reset
      //   $(this).find('.inner').css('height','');
      //   $(this).css('padding-bottom','');
      //
      //   // Set
      //   var h = parseInt($(this).outerHeight());
      //   var m = parseInt($(this).css('max-height'));
      //
      //   $(this).find('.inner').css('height',(h > m ? m : h)+'px');
      //   $(this).css('padding-bottom','0');
      // })
    }).trigger('resize');
  }
})
