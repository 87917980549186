$(function () {
  $('.panel').each(function (elem) {
    $(this).on('click',function(event) {
      var target = $(event.target);

      if(target.is('a')) {
        // Invalid
      } else if(target.find('a[data-toggle="collapse"]').length) {
        target.find('a[data-toggle="collapse"]').click();
      } else if(target.closest('a[data-toggle="collapse"]').length) {
        target.closest('a[data-toggle="collapse"]').click();
      } else {
        // Invalid ?!?
      }
    })
  })
})
