$(function() {
  function getSceneDuration() {
    var innerBodyHeight = $('body > main').innerHeight();
    var adAreaHeight = $('.advertisement-area').parent().outerHeight();
    var socialIconsHeight = $('.social-icons-wrapper').outerHeight();
    var breadcrumbHeight = $('.breadcrumb-wrapper').outerHeight() || 0;
    return (innerBodyHeight - adAreaHeight - socialIconsHeight - breadcrumbHeight);
  }

  function getTriggerHook() {
    var socialIconsHeight = $('.social-icons-wrapper').outerHeight();
    return (($(window).height() - socialIconsHeight) / 2) / $(window).height();
  }

  function createScene() {
    return new ScrollMagic.Scene({
      duration: getSceneDuration(),
      triggerElement: 'body > main',
      triggerHook: getTriggerHook(),
    }).setPin('.social-icons-wrapper');
  }

  var controller = new ScrollMagic.Controller();
  var scene = null;

  $(window).on('resize', function(event) {
    if ($(window).width() < 768) {
      controller.removeScene(scene);
      if (scene) {
        scene.destroy(true);
      }
      scene = null;
    } else if (scene === null) {
      scene = createScene().addTo(controller);
      controller.update(false);
    } else {
      scene.duration(getSceneDuration());
      scene.triggerHook(getTriggerHook());
      scene.update(false);
    }
  }).trigger('resize');
})
